// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/dealList/Pagination.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/dealList/Pagination.tsx");
  import.meta.hot.lastModified = "1708335860440.164";
}
// REMIX HMR END

import { Button, HStack } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "@remix-run/react";
import { BsChevronDoubleLeft, BsChevronDoubleRight, BsChevronLeft, BsChevronRight, BsThreeDots } from "react-icons/bs";
export const Pagination = props => {
  _s();
  const {
    currentPage,
    limit,
    total,
    root = "/deals/neueste"
  } = props;
  const maxpage = Math.round(total / limit);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  async function fetchData(page) {
    const params = new URLSearchParams();
    params.set("page", String(page));
    // setSearchParams(params, {
    //   replace: true,
    // });
    navigate(`${root}/?${params.toString()}`, {
      replace: true
    });
  }
  return <>
      <HStack display={{
      base: "none",
      md: "flex"
    }}>
        {currentPage > 2 && <Button onClick={() => fetchData(currentPage - 1)}>
            <BsChevronLeft />
          </Button>}

        {currentPage > 3 && <Button onClick={() => fetchData(1)}>1</Button>}
        {currentPage > 4 && <Button variant={"ghost"}>
            <BsThreeDots />
          </Button>}
        {currentPage > 1 && [2, 1].map(p => currentPage - p > 0 && <Button key={"page-" + (currentPage - p)} onClick={() => fetchData(currentPage - p)}>
                  {currentPage - p}
                </Button>)}
        <Button colorScheme="blue">{currentPage}</Button>
        {currentPage < maxpage - 1 && [1, 2].map(p => currentPage + p < maxpage && <Button key={"page-" + (currentPage + p)} onClick={() => fetchData(currentPage + p)}>
                  {currentPage + p}
                </Button>)}
        {currentPage <= maxpage - 3 && <Button variant={"ghost"}>
            <BsThreeDots />
          </Button>}
        {currentPage <= maxpage - 1 && <Button onClick={() => fetchData(maxpage)}>{maxpage}</Button>}

        {currentPage < maxpage - 1 && <Button onClick={() => fetchData(currentPage + 1)}>
            <BsChevronRight />
          </Button>}
      </HStack>
      <HStack display={{
      base: "flex",
      md: "none"
    }}>
        {currentPage > 1 && <Button onClick={() => fetchData(1)}>
            <BsChevronDoubleLeft />
          </Button>}
        {currentPage > 1 && <Button onClick={() => fetchData(currentPage - 1)}>
            <BsChevronLeft />
          </Button>}

        <Button colorScheme="blue">{currentPage}</Button>

        {currentPage < maxpage - 1 && <Button onClick={() => fetchData(currentPage + 1)}>
            <BsChevronRight />
          </Button>}
        {currentPage <= maxpage - 1 && <Button onClick={() => fetchData(maxpage)}>
            <BsChevronDoubleRight />
          </Button>}
      </HStack>
    </>;
};
_s(Pagination, "SzCFK1kXF0i0Rzp3n9XyE2BuEgU=", false, function () {
  return [useSearchParams, useNavigate];
});
_c = Pagination;
var _c;
$RefreshReg$(_c, "Pagination");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;