// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/search/SearchListSmall.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/search/SearchListSmall.tsx");
  import.meta.hot.lastModified = "1708510956415.2344";
}
// REMIX HMR END

import { Box, Center, Flex, Heading, Image, LinkBox, LinkOverlay, Spacer, Stack, StackDivider, Text, VStack, useColorModeValue } from "@chakra-ui/react";
import moment from "moment";
import { DealPrice } from "../dealList/DealPrice";
import { DealButton } from "../dealList";
import { isEditor, useAuth } from "~/utils/userUtils";
import { useEffect, useState } from "react";
import { EditPostButtons } from "../post/PostDashboard";
export const SearchListSmall = props => {
  _s();
  const {
    hits
  } = props;
  const color = useColorModeValue("gray.100", "gray.800");
  const [editor, setEditor] = useState(false);
  const {
    user,
    login,
    logout
  } = useAuth();
  useEffect(() => {
    setEditor(isEditor(user));
  }, [user]);
  return <>
      <VStack divider={<StackDivider borderColor="gray.200" />} spacing={1} align="stretch">
        {hits.map(hit => <Center key={"search-" + hit.id} py={5} position={"relative"}>
            <LinkBox as="article">
              <Flex role={"group"} p={3} maxW={"330px"} w={"full"} bg={color}
          // boxShadow={"2xl"}
          rounded={"lg"} pos={"relative"}
          // zIndex={1}
          >
                <Box rounded={"lg"} ml={-7} bgColor={"white"} pos={"relative"} boxSize={100} w={100} mr={4}>
                  <Image loading="lazy" padding={1} rounded={"lg"} height={"100px"} width={"100px"} objectFit={"cover"} src={hit.titleImageThumb}
              // srcSet={hit.srcSet}
              alt={hit.title} />
                  {(moment(hit.expirationDate).isBefore(moment()) || moment(hit.dealDate).isBefore(moment().subtract(3, "days"))) && <Box rounded={"lg"} top={0} bottom={0} left={0} right={0} pos={"absolute"} bgColor={"whiteAlpha.700"} zIndex={10} overflow={"hidden"}>
                      <Box bg={"white"} mt={10} width="105%" ml={-1} transform={"rotateZ(-4deg)"} color={"gray"} textAlign={"center"} fontWeight={"bold"}>
                        Abgelaufen
                      </Box>
                    </Box>}
                </Box>
                <Stack pt={0} align={"left"} flex="1">
                  <Text color={"gray.500"} fontSize={"sm"} textTransform={"uppercase"}>
                    {moment(hit.dealDate).format("DD.MM.YYYY HH:mm")}
                  </Text>
                  <Heading fontSize={"l"} fontFamily={"body"} fontWeight={400} maxW={"100%"} noOfLines={5}>
                    <LinkOverlay href={`/deal/${hit.dealURL}`}>
                      <Box dangerouslySetInnerHTML={{
                    __html: hit._formatted.title.replaceAll("__ais-highlight__", "<b>").replaceAll("__/ais-highlight__", "</b>")
                  }} />
                    </LinkOverlay>
                  </Heading>
                  <Stack direction={"row"} align={"center"}>
                    <DealPrice price={hit.price} originalPrice={hit.originalPrice} discount={hit.discount} />
                  </Stack>
                  <Stack direction={"row"} align={"center"}>
                    <DealButton affiliateLink={hit.affiliateLink} id={hit.id} />
                    <Spacer />
                    {editor && <EditPostButtons item={hit} aria-label="Edit Post" size={{
                  base: "sm",
                  md: "sm",
                  lg: "md"
                }} />}
                  </Stack>
                </Stack>
              </Flex>
            </LinkBox>
          </Center>)}
      </VStack>
    </>;
};
_s(SearchListSmall, "n0v+snCjrRKpC29BQTVKW48R43g=", false, function () {
  return [useColorModeValue, useAuth];
});
_c = SearchListSmall;
var _c;
$RefreshReg$(_c, "SearchListSmall");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;